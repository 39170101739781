import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBestPlaceAnalyticsReportPrices } from 'shared/actions/bestPlaceAnalytics';
import { openBestPlaceReportModal } from 'shared/actions/bestPlaceReportModal';
import { selectBestPlaceAnalyticsReportExampleUrl } from 'shared/selectors/bestPlaceAnalytics';
import { selectBestPlaceAnalyticsReportPrices } from 'shared/selectors/bestPlaceAnalytics/selectBestPlaceAnalyticsReportPrices';
import { hasGetParameter } from 'shared/utils/hasGetParameter';

import { BestPlaceReportDescription } from '../../components/BestPlaceReportDescription';
import { useTrackBestPlaceReport } from '../../hooks/useTrackBestPlaceReport';

export const BestPlaceReportDescriptionContainer: React.FC = () => {
  const dispatch = useDispatch();

  const reportExampleUrl = useSelector(selectBestPlaceAnalyticsReportExampleUrl);
  const { amount: priceWithoutDiscount, discountPrice: priceWithDiscount } = useSelector(
    selectBestPlaceAnalyticsReportPrices,
  );
  const trackBestPlaceReportButtonClick = useTrackBestPlaceReport();

  React.useEffect(() => {
    if (hasGetParameter('with_best_place') || hasGetParameter('transactionId')) {
      dispatch(openBestPlaceReportModal());
    }
  }, [dispatch]);

  const getReportPrices = React.useCallback(() => {
    dispatch(getBestPlaceAnalyticsReportPrices());
  }, [dispatch]);

  const handleBuyReport = React.useCallback(() => {
    trackBestPlaceReportButtonClick('click_buy_report');
    dispatch(openBestPlaceReportModal());
  }, [dispatch, trackBestPlaceReportButtonClick]);

  const handleExamineReportExample = React.useCallback(() => {
    trackBestPlaceReportButtonClick('click_see_report_example');
  }, [trackBestPlaceReportButtonClick]);

  return (
    <BestPlaceReportDescription
      priceWithoutDiscount={priceWithoutDiscount}
      priceWithDiscount={priceWithDiscount}
      reportExampleUrl={reportExampleUrl}
      getReportPrices={getReportPrices}
      handleBuyReport={handleBuyReport}
      handleExamineReportExample={handleExamineReportExample}
    />
  );
};

BestPlaceReportDescriptionContainer.displayName = 'BestPlaceReportDescriptionContainer';
