/* eslint-disable max-lines */
import { ErrorLogComponent } from '@cian/error-log-component';
import cx from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { AreaParts } from 'shared/components/AreaParts';
import { AuthRequiredBlock } from 'shared/components/AuthRequiredBlock';
import { BestPlaceReportDescriptionContainer } from 'shared/components/BestPlaceAnalytics/containers/BestPlaceReportDescriptionContainer';
import { CardSectionNew } from 'shared/components/CardSectionNew';
import { CommercialContactsMainContainer } from 'shared/components/CommercialContactsMain/CommercialContactsMainContainer';
import { IllegalConstructionBannerContainer } from 'shared/components/IllegalStructureBanner';
import { PossibleAssignmentContainer } from 'shared/components/PossibleAssignment';
import { SimilarOffersAnchor } from 'shared/components/SimilarOffersSection';
import { CommercialAboutOtherContainer } from 'shared/components/commercial/about_other/container';
import { CommercialAboutTCBCController } from 'shared/components/commercial/about_tcbc/CommercialAboutTCBCController';
import { TechnicalCharacterContainer } from 'shared/components/technical_character/container';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { BestPlaceReportModalContainer } from 'shared/containers/BestPlaceReportModalContainer';
import { BuyAnalyticsModalContainer } from 'shared/containers/BuyAnalyticsModal';
import { OfferSummaryInfoCommercial } from 'shared/containers/OfferSummaryInfoNew/OfferSummaryInfoCommercial';
import { IAgentSchema } from 'shared/repositories/offer-card/entities/agent/AgentSchema';
import { IBusinessShoppingCenterSchema } from 'shared/repositories/offer-card/entities/business_shopping_center/BusinessShoppingCenterSchema';
import { IOfferSchema } from 'shared/repositories/offer-card/entities/offer/OfferSchema';
import { selectIsAuthenticated, selectOfferAgent } from 'shared/selectors';
import { selectBestPlaceAnalyticsAvailable } from 'shared/selectors/bestPlaceAnalytics';
import { businessShoppingCenterSelector } from 'shared/selectors/offerData/offer/businessShoppingCenterSelector';
import { getIsCoworkingByOffer } from 'shared/utils/getIsCoworkingByOffer';

import * as styles from './CommercialPageContainer.css';
import { FeedbackPopupContainer as FeedBackPopupC } from '../../FeedbackExperiment/components/PopupWithComplaint/FeedbackPopup';
import { PopupFeedbackSentContainer } from '../../FeedbackExperiment/components/PopupWithForm/PopupFeedbackSent';
import { LabelsLayoutNew } from '../../components/LabelsLayout';
import {
  OfferCardPageLayout,
  OfferCardPageLayoutAside,
  OfferCardPageLayoutCenter,
  OfferCardPageLayoutFooter,
  OfferCardPageLayoutTop,
} from '../../components/OfferCardPageLayout';
import { AdminButtonsContainer } from '../../components/admin_buttons/container';
import { HeaderAdminPanelContainer } from '../../components/header_admin_panel/container';
import { OfferBreadcrumbsContainer } from '../../components/offer_breadcrumbs/container';
import { OfferGallery } from '../../components/offer_gallery';
import { OfferIsHiddenBlock } from '../../components/offer_hidden_block';
import { OfferRefusedContainer } from '../../components/offer_refused/container';
import { OfferUnpublishedContainer } from '../../components/offer_unpublished/container';
import { HideOfferTopPopup } from '../../components/popups/hide_offer_top_popup';
import { LegendModalContainer } from '../../components/popups/legend/container';
import { PrintModalContainer } from '../../components/popups/print/container';
import { RemoveModalContainer } from '../../components/popups/remove/container';
import { PrintInformationContainer } from '../../components/print_information/container';
import { PrintPhotoContainer } from '../../components/print_photo/container';
import { selectBuyAnalyticsReportAvailability } from '../../selectors/buyAnalyticsModal';
import { getOffer } from '../../selectors/newObject';
import { IState } from '../../store';
import { TgbBottomBannersContainer } from '../Adfox/TgbBottomBannersContainer';
import { AnalyticsBlockContainer } from '../AnalyticsBlockNew';
import { AsideRedesignedContainer } from '../AsideRedesigned/AsideRedesigned';
import { AuctionLabelContainer } from '../AuctionLabel';
import { BottomAuthorBlockContainer } from '../BottomAuthorBlockNew';
import { BuyAnalyticsButtonContainer } from '../BuyAnalyticsBlocks';
import { CallUnderDescriptionContainer } from '../CallUnderDescriptionContainer';
import { ChatContainer } from '../ChatContainer';
import { ChatPopupContainer } from '../ChatPopupContainer';
import { ComparisonNotificationLoadableContainer } from '../ComparisonNotificationContainer';
import { CoworkingAmenities } from '../CoworkingAmenities';
import { DealSaleLabelNewContainer } from '../DealSaleLabelContainer';
import { DepositPropertyLabelContainer } from '../DepositPropertyLabel';
import { DescriptionContainer } from '../Description/Description';
import { FavoritesFoldersContainer } from '../FavoritesFoldersContainer';
import { FlatViewOrderTooltipContainer } from '../FlatViewOrderTooltip';
import { IdenticalOffersContainer } from '../IdenticalOffersContainer';
import { MainNewContainer } from '../MainContainer';
import { NavigationContainerNew } from '../NavigationNew';
import { NotAvailablePopupContainer } from '../NotAvailablePopupContainer';
import { ObjectFactoidsContainer } from '../ObjectFactoid/ObjectFactoid';
import { ObjectSummaryTitleContainer } from '../ObjectSummaryTitle/ObjectSummaryTitleContainer';
import { OfferMapContainer } from '../OfferMapContainer';
import { OfferMetaDataContainer } from '../OfferMetaData/OfferMetaData';
import { GoodPriceLabelNewContainer } from '../OfferValuation/containers/GoodPriceLabel';
import { PhoneWarningModalContainer } from '../PhoneWarningModalContainer';
import { SeoLinksContainer } from '../SeoLinks/SeoLinksContainer';
import { SimilarOffersGroupsContainer } from '../SimilarOffersRedesigned';
import { TourContainer } from '../TourContainer';
import { UxfbCookieUpdater } from '../UxfbCookieUpdater';
import { VisitedOffersUpdater } from '../VisitedOffersUpdater';
import { WarningsContainer } from '../WarningsContainer';
import { RealtorRequestCommercialTrapContainer } from '../valuationTraps';

const CommercialPageContainer = () => {
  const context = useApplicationContext();
  const hiddenObjectsLkUrl = context.config.get<string>('hiddenObjects.lkUrl') || '';
  const offer = useSelector(getOffer);
  const isPublished = offer.status === 'published';
  const isHiddenByUser = useSelector((state: IState) => state.offerData.isHiddenByUser);
  const isTopPopupOpened = useSelector((state: IState) => state.hideOffer.isTopPopupOpened);
  const hideOfferStatus = useSelector((state: IState) => state.hideOffer.status);
  const hideOfferErrorMessage = useSelector((state: IState) => state.hideOffer.errorMessage);

  const isBuyAnalyticsButtonEnabled = useSelector(selectBuyAnalyticsReportAvailability);
  const isBestPlaceAnalyticsDisplayed = useSelector(selectBestPlaceAnalyticsAvailable);

  // Показываем возможность покупки аналитики от CreSpace только в том случае, если недоступна
  // аналитика от BestPlace
  const isCreSpaceAnalyticsDisplayed = !isBestPlaceAnalyticsDisplayed && isBuyAnalyticsButtonEnabled;

  const agent = useSelector(selectOfferAgent) as IAgentSchema | null;
  const isUserAuthenticated = useSelector(selectIsAuthenticated);

  const businessShoppingCenter = useSelector(businessShoppingCenterSelector) as IBusinessShoppingCenterSchema | null;
  const isCoworking = getIsCoworkingByOffer(offer);

  return (
    <>
      <NavigationContainerNew />
      <OfferCardPageLayout>
        <FeedBackPopupC />
        <PopupFeedbackSentContainer />
        <FlatViewOrderTooltipContainer />
        <OfferCardPageLayoutTop>
          <OfferBreadcrumbsContainer />
          <OfferMetaDataContainer />
          <HeaderAdminPanelContainer />
          <OfferRefusedContainer config={context.config} />
          <OfferUnpublishedContainer />
          <AdminButtonsContainer />
          <ErrorLogComponent logger={context.logger}>
            <WarningsContainer />
          </ErrorLogComponent>
        </OfferCardPageLayoutTop>

        {isTopPopupOpened && (
          <HideOfferTopPopup
            status={hideOfferStatus}
            hidden={Boolean(isHiddenByUser)}
            lkUrl={hiddenObjectsLkUrl}
            hideOfferErrorMessage={hideOfferErrorMessage}
          />
        )}

        {isHiddenByUser && <OfferIsHiddenBlock lkUrl={hiddenObjectsLkUrl} />}

        <OfferCardPageLayoutCenter>
          <ErrorLogComponent logger={context.logger}>
            <LabelsLayoutNew>
              <GoodPriceLabelNewContainer />
              <DealSaleLabelNewContainer />
              <AuctionLabelContainer />
              <DepositPropertyLabelContainer />
            </LabelsLayoutNew>
            <MainNewContainer />
          </ErrorLogComponent>
          {isPublished && (
            <div>
              <OfferGallery reactErrorLogger={context.logger} />
              <PrintPhotoContainer />
            </div>
          )}
          <ObjectSummaryTitleContainer />
          <ObjectFactoidsContainer />

          {isCreSpaceAnalyticsDisplayed && <AnalyticsBlockContainer />}
          {isBestPlaceAnalyticsDisplayed && (
            <div className={styles['description-buy-analytics-wrapper']}>
              <BestPlaceReportDescriptionContainer />
            </div>
          )}

          <IllegalConstructionBannerContainer />

          {!!offer.areaParts?.length && (
            <div className={styles['area-parts-wrapper']} id={'/area-parts'}>
              <AreaParts areaParts={offer.areaParts} bargainTerms={offer.bargainTerms} />
            </div>
          )}

          <div className={styles['description-wrapper']}>
            <DescriptionContainer />
          </div>

          {!isUserAuthenticated && (
            <CardSectionNew hideForPrint>
              <AuthRequiredBlock />
            </CardSectionNew>
          )}
          {isUserAuthenticated && <PossibleAssignmentContainer />}
          {isUserAuthenticated && <CoworkingAmenities />}

          <CallUnderDescriptionContainer />

          {isPublished && <CommercialContactsMainContainer />}

          {isUserAuthenticated && (
            <CardSectionNew>
              <OfferSummaryInfoCommercial />
            </CardSectionNew>
          )}

          {isUserAuthenticated && !!businessShoppingCenter && (
            <CommercialAboutTCBCController
              agent={agent}
              businessShoppingCenter={businessShoppingCenter}
              offer={offer as unknown as IOfferSchema}
            />
          )}
          <CardSectionNew hideForPrint>
            <TourContainer />
          </CardSectionNew>

          {isPublished && [
            <div key="published-data">
              <CardSectionNew>
                <ErrorLogComponent logger={context.logger}>
                  <OfferMapContainer />
                </ErrorLogComponent>
                {!isCoworking && (
                  <div className={styles['offer_card_page-technical']}>
                    {!businessShoppingCenter && <CommercialAboutOtherContainer />}
                    {!businessShoppingCenter && <TechnicalCharacterContainer />}
                    {isCreSpaceAnalyticsDisplayed && (
                      <div
                        className={cx(
                          styles['buy-analytics-button'],
                          !!businessShoppingCenter && styles['only-button'],
                        )}
                      >
                        <BuyAnalyticsButtonContainer />
                      </div>
                    )}
                  </div>
                )}
              </CardSectionNew>

              <CardSectionNew hideForPrint>
                <RealtorRequestCommercialTrapContainer />
              </CardSectionNew>

              <ErrorLogComponent logger={context.logger}>
                <CardSectionNew>
                  <BottomAuthorBlockContainer />
                </CardSectionNew>
              </ErrorLogComponent>
            </div>,
          ]}
        </OfferCardPageLayoutCenter>

        <OfferCardPageLayoutAside>
          <AsideRedesignedContainer />
        </OfferCardPageLayoutAside>
      </OfferCardPageLayout>
      <OfferCardPageLayoutFooter>
        <IdenticalOffersContainer />
        <SimilarOffersAnchor>
          <SimilarOffersGroupsContainer />
        </SimilarOffersAnchor>
        <SeoLinksContainer />
        <TgbBottomBannersContainer />
      </OfferCardPageLayoutFooter>

      <PrintModalContainer />
      <PrintInformationContainer />

      <LegendModalContainer />
      <RemoveModalContainer />

      <ChatContainer config={context.config} />
      <ChatPopupContainer />
      <NotAvailablePopupContainer />
      <FavoritesFoldersContainer />
      <BuyAnalyticsModalContainer />
      <BestPlaceReportModalContainer />
      <PhoneWarningModalContainer />
      <ComparisonNotificationLoadableContainer />
      <VisitedOffersUpdater />
      <UxfbCookieUpdater />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default CommercialPageContainer;
